import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Layout from "../components/page-objects/Layout";
import Header from "../components/page-objects/Header";
import BlogRow from "../components/page-objects/BlogRow";
import { graphql } from "gatsby";
import Spacer from "../components/ui-objects/Spacer";
import Affiliate from "../components/page-objects/Affiliate";
import Button from "../components/ui-objects/Button";
import Reviews from "../components/page-objects/Reviews";
import UspBar from "../components/page-objects/usp-bar";
import { FiCheckCircle } from '@react-icons/all-files/fi/FiCheckCircle';
import { FaChevronDown } from '@react-icons/all-files/fa/FaChevronDown';

// check of part of page is visible
function isVisible(value) {
  if (value) {
    return (
      'block'
    )
  } else {
    return (
      'none'
    )
  }
}

function checkBool(val) {
  if (val) {
    return true
  } else {
    return false
  }
}

const ProductPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout
      fullbg="false"
      // helmet seo content
      description={frontmatter.seoinfo.description}
      title={frontmatter.seoinfo.seotitle}
      keywords={frontmatter.seoinfo.keywords}
    >
      <div />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      >
        <section style={{ order: "0" }}>
          <Header
            headerImage={frontmatter.headerimagewrap.featuredimage.childImageSharp.fixed.srcWebp}
            headerImageAlttext={frontmatter.headerimagewrap.alttextheaderimage}
            headerPositie={frontmatter.headerpositie}
            promoblockVisible={frontmatter.headerpromo.headerpromovisible}
            promoblockContent={frontmatter.headerpromo.headerpromocontent}
            promoblockButtonVisible={frontmatter.headerpromo.headerpromobuttonvisible}
            promoblockButtonText={frontmatter.headerpromo.headerpromobuttonlabel}
            promoblockButtonUrl={frontmatter.headerpromo.headerpromobuttonurl}
            fullBackgroundImage={frontmatter.headerimagewrap.fullbgimage}
            headerHeight={""}
          />
        </section>
        <section className="backgroundblock dark-gray" style={{ padding: '20px 0' }}>
          <UspBar />
        </section>
        {/* if segment active */}
        { checkBool(frontmatter.waarommediationwrap.waarommediationvisible) ? (
        <section
          className={"backgroundblock " + frontmatter.waarommediationwrap.waarommediationbgcolor}
        >
          <div className="container">
            <h2>{frontmatter.waarommediationwrap.waarommediationtitel}</h2>
            <Spacer size="l" />
            <div className="row">
              <div className="col">
                <ul className="list-style list-style--none">
                    {frontmatter.waarommediationwrap.waarommediation.map((post, index) => (
                    <li key={index}>
                      <FiCheckCircle size="1.2em" color="hsl(191, 33%, 59%)" style={{ marginRight: "10px", marginTop: "-3px" }} /> {post}
                    </li>
                  ))
                  }
                </ul>
              </div>
              <div className="col d-flex justify-content-end d-none d-md-block">
                <div className="bordered-picture" style={{
                  backgroundImage: "url(" + frontmatter.waarommediationwrap.waarommediationimage.childImageSharp.fixed.srcWebp + ")",
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '320px',
                  height: '195px'
                }} />
              </div>
            </div>
          </div>
        </section>
        ) : null }
        {checkBool(frontmatter.watkunjeverwachtenwrap.watkunjeverwachtenvisible) ? (
        <section
          className={"backgroundblock " + frontmatter.watkunjeverwachtenwrap.watkunjeverwachtenbgcolor}
        >
          <div className="container" style={{ textAlign: 'left', padding: '0 40px' }}>
            <h3>{frontmatter.watkunjeverwachtenwrap.Watkunjeverwachtentitel}</h3>
            <Spacer size="l" />
            <div dangerouslySetInnerHTML={{ __html: frontmatter.watkunjeverwachtenwrap.watkunjeverwachten }} />
            <Spacer size="xxl" />
            <Button buttonType="primary" buttonLabel={frontmatter.watkunjeverwachtenwrap.Watkunjeverwachtenbuttontext} url={frontmatter.watkunjeverwachtenwrap.Watkunjeverwachtenbuttonlink} style={{ display: isVisible(frontmatter.watkunjeverwachtenwrap.watkunjeverwachtenbuttonvisible) }} />
          </div>
        </section>
        ) : null }
        <section
          className="backgroundblock white"
        >
          <div className="container">
            <h3 style={{ textAlign: "center" }}>
              Goed om te weten
            </h3>
            <Spacer size="l" />
            <BlogRow />
          </div>
        </section>
        {checkBool(frontmatter.veelgesteldevragenwrap.veelgesteldevragenvisible) ? (
        <section
          className={"backgroundblock " + frontmatter.veelgesteldevragenwrap.veelgesteldevragenbgcolor}
        >
          <div className="container">
            <h3>{frontmatter.veelgesteldevragenwrap.veelgesteldevragentitel}</h3>
            <Spacer size="l" />
            <div className="row">
              <div className="col">
                <Fragment>
                  {frontmatter.veelgesteldevragenwrap.veelgesteldevragen.faq.map((post, index) => (
                  <div>
                    <div className="collapsebar-header" key={index} data-bs-toggle="collapse" href={"#collapsible" + index} role="button" aria-expanded="false" aria-controls="collapsible">
                      <FaChevronDown className="icon" size="1.2em" color="hsl(49, 53%, 42%)" style={{ marginRight: "10px", marginTop: "-3px" }}/>
                      {post.veelgesteldevragenvraag}
                    </div>
                      <div className="collapse collapsebar-content" id={"collapsible" + index}>
                        <p>{post.veelgesteldevragenantwoord}</p>
                    </div>
                  </div>
                  ))}
                </Fragment>
              </div>
              <div className="col d-flex justify-content-end d-none d-md-block">
                <div className="bordered-picture" style={{
                  backgroundImage: "url(" + frontmatter.veelgesteldevragenwrap.veelgesteldevragenimage.childImageSharp.fixed.srcWebp + ")", 
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '320px', 
                  height: '195px' 
                }} />
              </div>
            </div>
            <Spacer size="xxl" />
            <Button style={{ display: isVisible(frontmatter.veelgesteldevragenwrap.veelgesteldevragenbuttonvisible) }} url={frontmatter.veelgesteldevragenwrap.veelgesteldevragenbuttonlink} buttonType="primary" buttonLabel={frontmatter.veelgesteldevragenwrap.veelgesteldevragenbuttontext} />
          </div>
        </section>
        ) : null }
        <section
          className={"backgroundblock navy"}
        >
          <div className="container">
            <Reviews />
          </div>
        </section>
        {checkBool(frontmatter.kanhelpenwrap.kanhelpenvisible) ? (
        <section
          className={"backgroundblock " + frontmatter.kanhelpenwrap.Kanhelpenbgcolor}
        >
          <div className="container" style={{ textAlign: 'center' }}>
            <h3>{frontmatter.kanhelpenwrap.Kanhelpentitel}</h3>
            <Spacer size="l" />
            <p>{frontmatter.kanhelpenwrap.kanhelpen}</p>
            <Spacer size="xl" />
            <Button style={{ display: isVisible(frontmatter.kanhelpenwrap.kanhelpenbuttonvisible) }} url={frontmatter.kanhelpenwrap.kanhelpenbuttonlink} buttonType="primary" buttonLabel={frontmatter.kanhelpenwrap.kanhelpenbuttontext} />
          </div>
        </section>
        ) : null }
        <section
          className="backgroundblock navy-light"
        >
          <Affiliate />
        </section>
    </div>
    </Layout >
  );
};

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProductPage;
export const pageQuery = graphql`
        query ProductPageID($id: String!) {
          markdownRemark(id: { eq: $id }) {
            id
    html
    fields {
      slug
    }
    frontmatter {
      headerimagewrap {
        featuredimage {
          childImageSharp {
            fixed(webpQuality: 90, width: 1600) {
              srcWebp
            }
          }
        }
        headerimagealttext
        fullbgimage
      }
      fullbgimage
      headerpositie
      headerpromo {
        headerpromobuttonlabel
        headerpromobuttonurl
        headerpromocontent
        headerpromobuttonvisible
        headerpromovisible
      }
      date(formatString: "MMMM DD, YYYY")
      title
      description
      tags
      waarommediationwrap {
        waarommediationvisible
        waarommediationtitel
        waarommediationbgcolor
        waarommediation
        waarommediationimage {
          childImageSharp {
            fixed(webpQuality: 90, width: 350) {
              srcWebp
            }
          }
        }
      }
      watkunjeverwachtenwrap {
        watkunjeverwachtenvisible
        watkunjeverwachtenbgcolor
        Watkunjeverwachtentitel
        watkunjeverwachten
        watkunjeverwachtenbuttonvisible
        Watkunjeverwachtenbuttontext
        Watkunjeverwachtenbuttonlink
      }
      veelgesteldevragenwrap {
        veelgesteldevragenvisible
        veelgesteldevragenbgcolor
        veelgesteldevragentitel
        veelgesteldevragen {
          faq {
            veelgesteldevragenvraag
            veelgesteldevragenantwoord
          }
        }
        veelgesteldevragenbuttonlink
        veelgesteldevragenbuttontext
        veelgesteldevragenbuttonvisible
        veelgesteldevragenimage {
          publicURL
          childImageSharp {
            fixed(webpQuality: 90, width: 350) {
              srcWebp
            }
          }
        }
      }
      kanhelpenwrap {
        kanhelpenvisible
        kanhelpenbgcolor
        Kanhelpentitel
        kanhelpen
        kanhelpenbuttonlink
        kanhelpenbuttontext
        kanhelpenbuttonvisible
      }
      seoinfo {
        description
        seotitle
        keywords
      }
    }
    }
  }
`;
